import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "../Queries/graphql/orderLinesAndEvents";

import commentFragments from "./../Queries/graphql/commentFragments";

const TRANSFER_ORDER = gql`
  mutation transferOrder($id: ID!, $input: TransferOrderInput!) {
    transferOrder(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        comments {
          ...CommentQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${commentFragments.query}
`;

class TransferOrderUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation
        mutation={TRANSFER_ORDER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const transferOrder = input => {
            update({
              variables: {
                id: order.id,
                input: input
              }
            });
          };
          return render({ order, transferOrder, errors: this.state.errors });
        }}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
    this.props.onError();
  };

  onCompleted = response => {
    window.location.href = "/orders/" + response.transferOrder.order.id;
  };
}

TransferOrderUpdater.propTypes = {
  order: OrderObject,
  onError: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default TransferOrderUpdater;

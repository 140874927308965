import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "../Queries/OrderQuery";
import { formatD } from "Util/dollars";

import dateFuncs from "Form/dateFuncs";

import { showInvoicePeriodCol } from "./businessRules";
import { AgGridReact } from "ag-grid-react";

class TableView extends React.Component {
  state = {
    selections: [],
    globalPartnerRef: "",
    lines: this.props.invoiceLines.map(l => ({ ...l }))
  };

  render() {
    const { order, close } = this.props;

    return (
      <React.Fragment>
        <div className="ag-theme-balham ag-grid-wrapper auto-height">
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={this.state.lines}
            columnDefs={this.columnDefs()}
            defaultColDef={this.defaultColDef}
            singleClickEdit={true}
            domLayout="autoHeight"
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={this.setSelections}
            stopEditingWhenCellsLoseFocus={true}
          />
        </div>
        <this.props.invoiceLinesUpdater
          render={({ processLines }) => (
            <div className="process-invoices-bottom-area">
              <div>
                {this.props.showPartnerRef && (
                  <React.Fragment>
                    <label htmlFor="globalPartnerRef">
                      Update partner ref for all lines
                    </label>
                    <input
                      type="text"
                      id="globalPartnerRef"
                      value={this.state.globalPartnerRef}
                      onChange={e => this.updatePartnerRef(e.target.value)}
                    />
                  </React.Fragment>
                )}
              </div>
              <this.props.processComponent
                orderId={order.id}
                selections={this.state.selections}
                processLines={processLines}
                close={close}
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }

  columnDefs = () => {
    const { order } = this.props;
    let cols = [
      {
        headerName: "Description",
        field: "orderLine.description",
        flex: 1,
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: "Type",
        field: "orderLine.saleType",
        width: 60
      },
      {
        headerName: "Qty",
        field: "orderLine.quantity",
        width: 60
      }
    ];

    if (order.uiState.showContractPricing) {
      cols.push({
        headerName: "Contract (exc GST)",
        children: [
          {
            headerName: "Unit",
            field: "sellUnitPriceExc",
            valueFormatter: params => formatD(params.value),
            width: 80,
            cellStyle: { textAlign: "right" }
          },
          {
            headerName: "Line",
            field: "lineSellUnitPriceExc",
            valueFormatter: params => formatD(params.value),
            width: 80,
            cellStyle: { textAlign: "right" }
          }
        ]
      });
    }

    if (order.uiState.showNpPricing) {
      if (!order.uiState.currentUserIsHeadOffice) {
        cols.push({
          headerName: "NP Val (inc GST)",
          children: [
            {
              headerName: "Unit",
              field: "buyUnitPriceInc",
              valueFormatter: params => formatD(params.value),
              width: 80,
              cellStyle: { textAlign: "right" }
            },
            {
              headerName: "Line",
              field: "lineBuyUnitPriceInc",
              valueFormatter: params => formatD(params.value),
              width: 80,
              cellStyle: { textAlign: "right" }
            }
          ]
        });
      } else {
        cols.push({
          headerName: "NP Val (exc GST)",
          children: [
            {
              headerName: "Unit",
              field: "buyUnitPriceExc",
              valueFormatter: params => formatD(params.value),
              width: 80,
              cellStyle: { textAlign: "right" }
            },
            {
              headerName: "Line",
              field: "lineBuyUnitPriceExc",
              valueFormatter: params => formatD(params.value),
              width: 80,
              cellStyle: { textAlign: "right" }
            }
          ]
        });
      }
    }

    cols.push(
      {
        headerName: "Fulfillment Status",
        field: "orderLine.status",
        width: 120
      },
      {
        headerName: "Delivery Date",
        field: "orderLine.deliveredOn",
        width: 120,
        valueFormatter: params => dateFuncs.displayDateYY(params.value)
      }
    );

    if (showInvoicePeriodCol(this.props.order)) {
      cols.push({
        headerName: "Invoice Period",
        field: "periodDesc",
        width: 120
      });
    }
    if (this.props.showPartnerRef) {
      cols.push({
        headerName: "Partner Ref",
        field: "partnerReference",
        width: 150,
        // TODO: Doesn't work, uses AgGridReact with this function as column defs
        editable: true,
        cellClass: "editable"
      });
    }

    return cols;
  };

  defaultColDef = {
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  };

  onGridReady = params => {
    this.agGridApi = params.api;
  };

  setSelections = () => {
    this.setState({ selections: this.agGridApi.getSelectedRows() });
  };

  updatePartnerRef = val => {
    this.setState({ globalPartnerRef: val });
    this.agGridApi.forEachNode(rowNode => {
      rowNode.setData({ ...rowNode.data, partnerReference: val });
    });
    this.setSelections();
  };
}

TableView.propTypes = {
  order: OrderObject.isRequired,
  close: PropTypes.func.isRequired,
  invoiceLines: PropTypes.array.isRequired,
  showPartnerRef: PropTypes.bool.isRequired,
  invoiceLinesUpdater: PropTypes.func.isRequired,
  processComponent: PropTypes.func.isRequired
};

export default TableView;

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";

const SPECIAL_ORDER_HOME_MOD = "home_mod";
const SPECIAL_ORDER_REPAIR = "repair";
const SPECIAL_ORDER_STANDARD = "no";

class AssignUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render, disableUIFunc } = this.props;

    return (
      <GraphqlWrapper>
        <Mutation
          mutation={ASSIGN_LINES}
          onError={this.onError}
          onCompleted={this.onCompleted}
        >
          {(update, { loading, error }) => {
            const assignSelections = (
              partnerId,
              repairLinesSelected,
              homeModLinesSelected,
              selections
            ) => {
              let specialOrder = SPECIAL_ORDER_STANDARD;
              if (repairLinesSelected && homeModLinesSelected) {
                // We should never get to this state as the assign buttons
                // should be disabled when both repair and home mod are selected
                console.log("ERROR: Cannot assign Repairs AND Home Mods");
              } else if (repairLinesSelected) {
                specialOrder = SPECIAL_ORDER_REPAIR;
              } else if (homeModLinesSelected) {
                specialOrder = SPECIAL_ORDER_HOME_MOD;
              }
              disableUIFunc(true);
              update({
                variables: {
                  id: order.id,
                  input: {
                    partnerId,
                    specialOrder,
                    ids: selections
                  }
                }
              });
            };
            return render({ order, assignSelections });
          }}
        </Mutation>
      </GraphqlWrapper>
    );
  }

  onError = () => {
    this.props.disableUIFunc(false);
  };

  onCompleted = data => {
    this.props.disableUIFunc(false);
    // handle server side errors
    if (
      data.assignLinesToPartner &&
      data.assignLinesToPartner.errors.length > 0
    ) {
      window.alert(data.assignLinesToPartner.errors[0].message);
    }
  };
}

AssignUpdater.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default AssignUpdater;

const ASSIGN_LINES = gql`
  mutation assignLinesToPartner($id: ID!, $input: AssignLinesInput!) {
    assignLinesToPartner(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
`;

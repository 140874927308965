import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../../../lib/AgGrid/CmpAgGrid";

import { formatD } from "Util/dollars";

import AddItemsBtn from "./AddItemsBtn";
import { isOnDvaContract } from "../../BusRules/access";

class ProductList extends React.Component {
  render() {
    const keyPrefix = isOnDvaContract(this.props.order) ? "dva" : "nonDVA";
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.productsQuery}
        responseDataKey="scheduleItems"
        localStorageKey={`${keyPrefix}-scheduleItems`}
        frameworkComponents={this.frameworkComponents}
      />
    );
  }

  frameworkComponents = {
    actionCellRenderer: row => {
      return (
        <AddItemsBtn
          key={row.data.sku + row.data.saleType}
          row={row.data}
          update={this.props.update}
          order={this.props.order}
        />
      );
    }
  };

  defaultColumnDefs = () => {
    const { order, saleType, saleTypeFilterOptions } = this.props;
    let saleTypeValues = saleTypeFilterOptions;
    if (saleType === "FREIGHT") {
      saleTypeValues = ["FREIGHT"];
    } else if (saleType !== "") {
      saleTypeValues = [saleType];
      if (order.uiState.allowAddFreight) {
        saleTypeValues.push("FREIGHT");
      }
      if (saleType === "HIRE") {
        saleTypeValues.push("RECYCLED");
      }
      saleTypeValues = saleTypeValues.sort();
    }

    let columns = [
      {
        headerName: "Sku",
        field: "sku",
        width: 120,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Size",
        field: "size",
        width: 80,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Unit",
        field: "unit",
        width: 100,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Description",
        field: "description",
        width: isOnDvaContract(order) ? 510 : 430,
        filter: "agTextColumnFilter"
      }
    ];
    if (isOnDvaContract(order)) {
      columns.push({
        headerName: "RAP#",
        field: "rapScheduleCode",
        width: 100,
        filter: "agTextColumnFilter"
      });
    }
    columns.push({
      headerName: "Sale Type",
      field: "saleType",
      width: 100,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      cellEditorParams: {
        values: saleTypeValues.filter(saleType => saleType !== "FREIGHT")
      },
      valueSetter: params => {
        params.node.setData({
          ...params.data,
          saleType: params.newValue,
          sellUnitPriceExc: null,
          actionDisabled: true
        });
      },
      onCellValueChanged: params => {
        params.api.tabToNextCell();
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: "sellUnitPriceExc"
        });
      },
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: saleTypeValues
      }
    });
    let pricingCols = [
      {
        headerName: "Contract",
        field: "sellUnitPriceExc",
        width: 90,
        valueFormatter: params => formatD(params.value),
        sortable: false,
        filter: false,
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
          precision: 2
        },
        valueSetter: params => {
          params.node.setData({
            ...params.data,
            sellUnitPriceExc: params.newValue,
            manualPrice: true,
            actionDisabled: !params.newValue
          });
        }
      },
      {
        headerName: "NP",
        field: "buyUnitPriceExc",
        width: 90,
        valueFormatter: params => formatD(params.value),
        sortable: false,
        filter: false,
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
          precision: 2
        },
        valueSetter: params => {
          params.node.setData({
            ...params.data,
            buyUnitPriceExc: params.newValue,
            manualPrice: true
          });
        }
      }
    ];
    if (!isOnDvaContract(order)) {
      pricingCols.push({
        headerName: "Bulk",
        field: "bulkUnitPriceExc",
        width: 90,
        valueFormatter: params => formatD(params.value),
        sortable: false,
        filter: false,
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
          precision: 2
        },
        valueSetter: params => {
          params.node.setData({
            ...params.data,
            bulkUnitPriceExc: params.newValue,
            manualPrice: true
          });
        }
      });
    }
    columns.push({
      headerName: "Unit Price (exc GST)",
      children: pricingCols
    });
    if (!isOnDvaContract(order)) {
      columns.push({
        headerName: "Bulk Qty",
        field: "bulkQuantity",
        width: 90,
        sortable: false,
        filter: false
      });
    }
    columns.push({
      headerName: "Qty to Add",
      field: "actionDisabled",
      width: 150,
      sortable: false,
      filter: false,
      cellRenderer: "actionCellRenderer"
    });
    return columns;
  };

  productsQuery = () => {
    const { order, saleType } = this.props;
    const contractId = order.contract.id;
    const orderId = order.id;

    return gql`
      query ScheduleItems($agGridParams: RequestParamInput!) {
        scheduleItems(agGridParams: $agGridParams, contractId: ${contractId}, orderId: ${orderId}, saleType: "${saleType}") {
          id
          sku
          size
          unit
          description
          rapScheduleCode
          saleType
          buyUnitPriceExc
          sellUnitPriceExc
          bulkUnitPriceExc
          bulkQuantity
        }
      }
    `;
  };
}

ProductList.propTypes = {
  currentUser: PropTypes.object,
  order: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  saleTypeFilterOptions: PropTypes.arrayOf(PropTypes.string),
  saleType: PropTypes.string
};

ProductList.defaultProps = {
  saleTypeFilterOptions: ["BUY", "FREIGHT", "HIRE", "RTO"]
};

export default ProductList;

import { gql } from "@apollo/client";

import orderLineFragments from "./orderLineFragments";
import orderTotalsFragments from "./orderTotalsFragments";
import orderEventFragments from "./orderEventFragments";

const fragments = {
  query: gql`
    fragment OrderLinesAndEventsQuery on Order {
      orderLines {
        ...OrderLineQuery
      }
      orderTotals {
        ...OrderTotalQuery
      }
      events {
        ...EventQuery
      }
      documentCategoryOptions
      documentAllowedMimeTypes
    }
    ${orderLineFragments.query}
    ${orderTotalsFragments.query}
    ${orderEventFragments.query}
  `
};

export default fragments;

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import clientFragments from "../Queries/graphql/clientFragments";
import { OrderObject } from "../Queries/OrderQuery";
import orderFragments from "../Queries/graphql/orderFragments";
import orderEventFragments from "../Queries/graphql/orderEventFragments";

const ADD_CLIENT_TO_ORDER = gql`
  mutation addClientToOrder($id: ID!, $reference: String) {
    addClientToOrder(id: $id, reference: $reference) {
      order {
        ...OrderFields
        client {
          ...ClientQuery
          genderOptions
          cardTypeOptions
          stateOptions
          statusOptions
          fundingOptions
        }
        events {
          ...EventQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${clientFragments.query}
  ${orderEventFragments.query}
`;

class ClientAdder extends React.Component {
  render() {
    const { order, onComplete, render } = this.props;
    return (
      <Mutation
        mutation={ADD_CLIENT_TO_ORDER}
        onError={this.onError}
        onCompleted={onComplete}
      >
        {(update, { loading, error }) => {
          const updateFunc = client => {
            let reference = null;
            if (client && client.hasOwnProperty("reference")) {
              reference = client.reference;
            }
            update({
              variables: {
                id: order.id,
                reference: reference
              }
            });
          };
          return render({ update: updateFunc });
        }}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
  };
}

ClientAdder.propTypes = {
  order: OrderObject.isRequired,
  onComplete: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default ClientAdder;

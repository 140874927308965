import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import commentFragments from "./../Queries/graphql/commentFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const ADD_COMMENT_UPDATER = gql`
  mutation createCommentForOrder($id: ID!, $input: CommentInput!) {
    createCommentForOrder(id: $id, input: $input) {
      order {
        id
        updatedAt
        commentsCount
        unreadCommentsCount
        comments {
          ...CommentQuery
        }
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${commentFragments.query}
  ${orderEventFragments.query}
`;

class CreateCommentForOrder extends React.Component {
  state = { errors: null };
  render() {
    const { order, render, afterSave } = this.props;
    return (
      <Mutation
        mutation={ADD_COMMENT_UPDATER}
        onError={this.onError}
        onCompleted={afterSave}
      >
        {(update, { loading, error }) => {
          const addCommentToOrder = input => {
            update({
              variables: {
                id: order.id,
                input: input
              }
            });
          };
          return render({
            order,
            addCommentToOrder: addCommentToOrder
          });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

CreateCommentForOrder.propTypes = {
  order: OrderObject,
  render: PropTypes.func.isRequired,
  afterSave: PropTypes.func.isRequired
};

export default CreateCommentForOrder;

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";

class ProductAdder extends React.Component {
  render() {
    const { render, disableUIFunc } = this.props;
    return (
      <Mutation
        mutation={ADD_LINE_TO_ORDER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const updateFunc = args => {
            disableUIFunc(true);
            update(args);
          };
          return render({ update: updateFunc });
        }}
      </Mutation>
    );
  }

  onCompleted = () => {
    this.props.disableUIFunc(false);
  };

  onError = () => {
    this.props.disableUIFunc(false);
  };
}

ProductAdder.propTypes = {
  render: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default ProductAdder;

const ADD_LINE_TO_ORDER = gql`
  mutation createOrderLine($id: ID!, $input: AddOrderLineInput!) {
    createOrderLine(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
`;

import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";

const UniqueScheduleCodeQuery = ({ render, contractId }) => {
  return (
    <GraphqlWrapper>
      <Query query={gqlQuery(contractId)}>
        {({ loading, error, data }) => {
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error</div>;
          return render({
            uniqueScheduleCodes: data.contract.uniqueScheduleCodes,
            nonContractedItemSizes: data.nonContractedItemSizes
          });
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlQuery = contractId => {
  return gql`
    query Contract {
      contract(id:${contractId}) {
        uniqueScheduleCodes
      }
      nonContractedItemSizes
    }
  `;
};

UniqueScheduleCodeQuery.propTypes = {
  render: PropTypes.func.isRequired,
  contractId: PropTypes.number.isRequired
};

export default UniqueScheduleCodeQuery;

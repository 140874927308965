import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";
import commentFragments from "./../Queries/graphql/commentFragments";
import { OrderObject } from "../Queries/OrderQuery";

const MUTATION = gql`
  mutation createShipmentForLines($id: ID!, $input: CreateShipmentInput!) {
    createShipmentForLines(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        comments {
          ...CommentQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${commentFragments.query}
`;

class CreateShipmentUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, onError, onCompleted, render } = this.props;
    return (
      <Mutation mutation={MUTATION} onError={onError} onCompleted={onCompleted}>
        {(update, { loading, error }) => {
          const createShipment = input => {
            update({
              variables: {
                id: order.id,
                input: input
              }
            });
          };
          return render({ createShipment });
        }}
      </Mutation>
    );
  }
}

CreateShipmentUpdater.propTypes = {
  order: OrderObject.isRequired,
  onError: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default CreateShipmentUpdater;

import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";
import orderLineFragments from "./../Queries/graphql/orderLineFragments";

const ORDER_FIELDS = `
  order {
    ...OrderFields
    events {
      ...EventQuery
    }
    orderLines {
      ...OrderLineQuery
    }
  }
  errors {
    path
    message
  }
`;

const ADD_REPAIR_LINE = gql`
  mutation addRepairLine($id: ID!) {
    addRepairLine(id: $id) {
      ${ORDER_FIELDS}
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${orderLineFragments.query}
`;

const ADD_HOME_MOD_LINE = gql`
  mutation addHomeModLine($id: ID!) {
    addHomeModLine(id: $id) {
      ${ORDER_FIELDS}
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${orderLineFragments.query}
`;

const ADD_COMPLEX_HOME_MOD_LINE = gql`
  mutation addHomeModLine($id: ID!) {
    addHomeModLine(id: $id, complex: true) {
      ${ORDER_FIELDS}
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${orderLineFragments.query}
`;

class AddRepairHomeModsBtn extends React.Component {
  state = { isOpen: false };
  render() {
    return (
      <a
        className={
          "button button--dropdown" + (this.state.isOpen ? " is-open" : "")
        }
        onClick={this.toggle}
      >
        Add Repairs/Home Mods
        {this.state.isOpen && (
          <ul className="button__menu">
            <li>
              {this.props.order.uiState.showAddRepairLineBtn && (
                <Mutation mutation={ADD_REPAIR_LINE} onError={this.onError}>
                  {(update, { loading, error }) => (
                    <a
                      onClick={() => this.addLine(update)}
                      className="button__menu__link"
                    >
                      Add Repair Item
                    </a>
                  )}
                </Mutation>
              )}
            </li>
            {this.props.order.uiState.showAddHomeModsLineBtn && (
              <React.Fragment>
                <li>
                  <Mutation mutation={ADD_HOME_MOD_LINE} onError={this.onError}>
                    {(update, { loading, error }) => (
                      <a
                        onClick={() => this.addLine(update)}
                        className="button__menu__link"
                      >
                        Add Minor Home Mod Item
                      </a>
                    )}
                  </Mutation>
                </li>
                <li>
                  <Mutation
                    mutation={ADD_COMPLEX_HOME_MOD_LINE}
                    onError={this.onError}
                  >
                    {(update, { loading, error }) => (
                      <a
                        onClick={() => this.addLine(update)}
                        className="button__menu__link"
                      >
                        Add Complex Home Mod Item
                      </a>
                    )}
                  </Mutation>
                </li>
              </React.Fragment>
            )}
          </ul>
        )}
      </a>
    );
  }

  addLine = update => {
    update({
      variables: {
        id: this.props.order.id
      }
    });
  };

  toggle = () => this.setState({ isOpen: !this.state.isOpen });
}

AddRepairHomeModsBtn.propTypes = {
  order: OrderObject
};

export default AddRepairHomeModsBtn;

import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridOrderLink from "../lib/AgGrid/CmpAgGridOrderLink";
import StatusCellRenderer from "../Orders/List/StatusCellRenderer";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        defaultSortModel={this.defaultSortModel()}
        gqlQuery={this.clientOrdersQuery}
        frameworkComponents={this.frameworkComponents}
        responseDataKey="clientOrders"
      />
    );
  }

  frameworkComponents = {
    statusCellRenderer: params => {
      return <StatusCellRenderer params={params} />;
    },
  };

  defaultColumnDefs = () => [
    {
      headerName: "CMP Order #",
      field: "cmpOrderNumber",
      colId: "id",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridOrderLink,
      cellRendererParams: params => {
        return {
          link: `/orders/${params.data.id}`
        };
      }
    },
    {
      headerName: "Web Order #",
      field: "webOrderNo",
      colId: "webOrderId",
      pinned: "left",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contract",
      field: "contract.contractCode",
      colId: "contract",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Status",
      field: "statusDesc",
      colId: "status",
      cellRenderer: "statusCellRenderer",
      valueGetter: params => (params.data || {}).statusDesc,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.currentUser.orderStatusDescriptions,
        suppressSorting: true,
        excelMode: "windows"
      }
    },
    {
      headerName: "Type",
      field: "orderType",
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Buy", "Hire", "Home Mod", "Repair", "RTO"]
      }
    },
    {
      headerName: "Order Date",
      field: "orderDate",
      colId: "dateReceived",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "Network Partner",
      field: "partner.name",
      colId: "partnerName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Healthcare Provider",
      field: "healthcareProvider.name",
      colId: "healthcareProviderName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Staff",
      field: "assignedStaffName",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.staffFilterValues(this.props.staffMembers),
        excelMode: "windows"
      }
    }
  ];

  defaultSortModel = () => [
    {
      colId: "id",
      sort: "desc"
    }
  ];

  clientOrdersQuery = () => {
    const id = this.clientIdFromPath();
    return gql`
      query ClientOrders($agGridParams: RequestParamInput!) {
        clientOrders(id: ${id}, agGridParams: $agGridParams) {
          id
          cmpOrderNumber
          webOrderNo
          status
          statusDesc
          orderType
          orderDate
          assignedStaffName
          contract {
            id
            contractCode
          }
          partner {
            id
            name
          }
          healthcareProvider {
            id
            name
          }
        }
      }
    `;
  };

  staffFilterValues = staffMembers => {
    let staffNames = staffMembers.map(staff => staff.name);
    staffNames.push("--unassigned--");
    return staffNames;
  };

  clientIdFromPath = () => {
    const matchPath = window.location.pathname.match(/clients\/\d+/);
    if (matchPath) {
      return matchPath[0].match(/\d+/)[0];
    } else {
      return null;
    }
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  staffMembers: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default TableView;

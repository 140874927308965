import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment OrderLineQuery on OrderLine {
      buyUnitPriceExc
      buyUnitPriceInc
      delivery
      description
      gst
      lineBuyUnitPriceExc
      lineBuyUnitPriceInc
      lineSellUnitPriceExc
      id
      manufacturerBrandCode
      manufacturerBrandName
      prescriber
      priorApproval
      quantity
      rapScheduleCode
      reason
      saleType
      sellUnitPriceExc
      size
      sku
      status
      trialStatus
      trialStatusOptions
      unit
      clientIsEligible
      allocated
      fulfillmentStatusOptions
      etaOn
      deliveredOn
      hireEndOn
      pickedUpOn
      weeksToOwn
      ownedByOn
      providerNotified
      assetDetails
      isHomeMod
      isRepair
      comment
      shipmentInvoiceNumber
      invoiceLines {
        id
        status
        statusDesc
        invoiceNumber
        invoiceDate
        partnerReference
        description
        invoiceIssue
        lineBuyUnitPriceExc
        lineBuyUnitPriceInc
        lineSellUnitPriceExc
        sellUnitPriceExc
        buyUnitPriceExc
        buyUnitPriceInc
        gst
      }
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    buyUnitPriceExc: PropTypes.number,
    delivery: PropTypes.string,
    description: PropTypes.string,
    gst: PropTypes.string,
    manufacturerBrandCode: PropTypes.string,
    manufacturerBrandName: PropTypes.string,
    prescriber: PropTypes.string,
    priorApproval: PropTypes.string,
    quantity: PropTypes.number,
    rapScheduleCode: PropTypes.string,
    reason: PropTypes.string,
    saleType: PropTypes.string,
    sellUnitPriceExc: PropTypes.number,
    size: PropTypes.string,
    sku: PropTypes.string,
    status: PropTypes.string,
    unit: PropTypes.string,
    clientIsEligible: PropTypes.bool,
    isHomeMod: PropTypes.bool,
    isRepair: PropTypes.bool,
    shipmentInvoiceNumber: PropTypes.string
  })
};

export default fragments;

import { gql } from "@apollo/client";

import commentFragments from "./../Queries/graphql/commentFragments";

const MUTATION = gql`
  mutation updateComment($id: ID!, $input: CommentInput!) {
    updateComment(id: $id, input: $input) {
      comment {
        ...CommentQuery
        order {
          id
          commentsCount
          unreadCommentsCount
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${commentFragments.query}
`;

export default MUTATION;

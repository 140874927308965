import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

const DischargeTransferQuery = ({ contractId, render }) => (
  <Query
    query={gqlDischargeTransferQuery(contractId)}
    fetchPolicy="cache-and-network"
  >
    {({ loading, error, data }) => {
      if (
        loading ||
        !data.dischargeCostCentres ||
        !data.salesReps ||
        !data.debtors
      )
        return <p>Loading...</p>;
      return render({
        costCentres: data.dischargeCostCentres,
        salesReps: data.salesReps,
        debtors: data.debtors
      });
    }}
  </Query>
);

const gqlDischargeTransferQuery = contractId => {
  return gql`
    query DischargeTransfer {
      dischargeCostCentres(contractId: ${contractId}, activeOnly: true) {
        code
        description
      }
      salesReps {
        id
        gpCode
        status
        firstName
        lastName
        name
        territory
        searchText
      }
      debtors(contractId: ${contractId}) {
        id
        code
        status
        notes
        searchText
      }
    }
  `;
};

DischargeTransferQuery.propTypes = {
  contractId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default DischargeTransferQuery;

import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment OrderTotalQuery on OrderTotals {
      buyUnitPriceExc
      quantity
      sellUnitPriceExc
      lineBuyUnitPriceExc
      lineBuyUnitPriceInc
      lineSellUnitPriceExc
    }
  `,
  object: PropTypes.shape({
    quantity: PropTypes.number,
    buyUnitPriceExc: PropTypes.number,
    sellUnitPriceExc: PropTypes.number,
    lineBuyUnitPriceExc: PropTypes.number,
    lineBuyUnitPriceInc: PropTypes.number,
    lineSellUnitPriceExc: PropTypes.number
  })
};

export default fragments;

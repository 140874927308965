import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";

const InventoryQuery = ({ render, onComplete, orderId }) => {
  return (
    <GraphqlWrapper>
      <Query
        query={gqlInventoryQuery(orderId)}
        fetchPolicy="cache-and-network"
        onCompleted={onComplete}
      >
        {({ loading, error, data }) => {
          if (error) return <div>Error</div>;
          return render({
            loading: loading || (data && !data.order),
            order: data.order || {}
          });
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlInventoryQuery = id => {
  return gql`
    query OrderInventory {
      order(id: ${id}) {
        id
          partner {
            id
            gpSupplierCode
          }
          stockCheck {
            id
            outsystemsError
            lastUpdate
            inventory {
              id
              nationalAvailable
              quantityAllocated
              quantityAvailable
              quantityOnHand
              quantityOnOrder
              sku
              state
              site
            }
          }
      }
    }
  `;
};

InventoryQuery.propTypes = {
  orderId: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default InventoryQuery;

import React from "react";
import { gql } from "@apollo/client";

import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import userFragments from "./../../UserAccounts/Queries/userFragments";
import { contractIdFromPath } from "../../lib/Util/url";
import PropTypes from "prop-types";
import ContractCheckbox from "./ContractCheckbox";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.contractNpUsersQuery}
        responseDataKey="contractNpUsers"
        frameworkComponents={this.frameworkComponents}
      />
    );
  }

  frameworkComponents = {
    onContractCellRenderer: row => {
      return <ContractCheckbox row={row} />;
    }
  };
  defaultColumnDefs = () => [
    {
      headerName: "Network Partner(s)",
      field: "organisationName",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.networkPartners.map(np => np.name),
        excelMode: "windows"
      },
      // Doesn't make sense to sort when there are multiple
      sortable: false
    },
    {
      headerName: "First Name",
      field: "firstName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contracts",
      colId: "contract",
      field: "contracts",
      // Doesn't make sense to sort when there are multiple
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Active", "Inactive"]
      }
    },
    {
      headerName: "On Contract",
      field: "onContract",
      filter: false,
      sortable: false,
      cellRenderer: "onContractCellRenderer"
    }
  ];

  contractNpUsersQuery = () => {
    const id = contractIdFromPath();
    return gql`
      query ContractNpUsers($agGridParams: RequestParamInput!) {
        contractNpUsers(agGridParams: $agGridParams, contractId:${id}) {
          onContract
          ...UserQuery
        }
      }
      ${userFragments.query}
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object,
  networkPartners: PropTypes.array.isRequired
};

export default TableView;

import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../../Queries/OrderQuery";
import documentFragments from "./../../Queries/graphql/documentFragments";
import orderEventFragments from "./../../Queries/graphql/orderEventFragments";

const CREATE_DOCUMENT_UPDATER = gql`
  mutation createDocumentForOrder($id: ID!, $input: DocumentInput!) {
    createDocumentForOrder(id: $id, input: $input) {
      order {
        id
        updatedAt
        documents {
          ...DocumentQuery
        }
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${documentFragments.query}
  ${orderEventFragments.query}
`;

class CreateDocumentForOrder extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation
        mutation={CREATE_DOCUMENT_UPDATER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const addDocumentToOrder = input => {
            // console.log("addDocumentToOrders");
            // console.log(input);
            update({
              variables: {
                id: order.id,
                input: input
              }
            });
          };
          return render({
            order,
            addDocumentToOrder: addDocumentToOrder
          });
        }}
      </Mutation>
    );
  }
  onCompleted = () => this.props.complete();

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

CreateDocumentForOrder.propTypes = {
  order: OrderObject,
  render: PropTypes.func,
  complete: PropTypes.func.isRequired
};

export default CreateDocumentForOrder;

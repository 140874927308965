import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment AdditionalInfo on OrderAdditionalInfo {
      labelText
      value
      subInfo {
        labelText
        value
      }
    }
  `,
  object: PropTypes.shape({
    labelText: PropTypes.string.isRequired,
    value: PropTypes.string,
    subInfo: PropTypes.arrayOf(
      PropTypes.shape({
        labelText: PropTypes.string.isRequired,
        value: PropTypes.string
      })
    )
  })
};

export default fragments;

import React from "react";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import PropTypes from "prop-types";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.hcpClientsQuery}
        responseDataKey="healthcareProviderClients"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Client Reference Number",
      field: "reference",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/clients/${params.data.id}?tab=details`
        };
      }
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Given Name",
      field: "firstName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Active", "Inactive"]
      }
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Male", "Female"]
      }
    },
    {
      headerName: "Date of Birth",
      field: "dateOfBirth",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Mobile Number",
      field: "mobileNumber",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Alternative Contact Name",
      field: "altContactName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Alternative Contact Phone",
      field: "altPhoneNumber",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Card Type",
      field: "cardType",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Gold", "White"]
      }
    },
    {
      headerName: "Homecare Packages lvl 4",
      field: "homecarePackageLevelDesc",
      colId: "homecarePackageLevel",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["True", "False"]
      }
    },
    {
      headerName: "Residential Care Facility",
      field: "residentialCareFacilityDesc",
      colId: "residentialCareFacility",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["True", "False"]
      }
    },
    {
      headerName: "ACFI Classification",
      field: "acfiClassification",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Address",
      field: "address",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Postcode",
      field: "postcode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    }
  ];

  hcpClientsQuery = () => {
    const id = this.healthcareProviderIdFromPath();
    return gql`
      query HealthcareProviderClients($agGridParams: RequestParamInput!) {
        healthcareProviderClients(id: ${id}, agGridParams: $agGridParams) {
          id
          reference
          lastName
          firstName
          status
          gender
          dateOfBirth
          phoneNumber
          mobileNumber
          email
          altContactName
          altPhoneNumber
          cardType
          homecarePackageLevel
          residentialCareFacility
          acfiClassification
          address
          postcode
          city
          state
        }
      }
    `;
  };

  healthcareProviderIdFromPath = () => {
    const path = window.location.pathname.match(/healthcare_providers\/\d+/);
    if (path) {
      return path[0].match(/\d+/)[0];
    } else {
      return null;
    }
  };
}

TableView.propTypes = {
  currentUser: PropTypes.object
};
export default TableView;

import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import ClientForm from "./ClientForm";
import ClientFragments from "./../Queries/graphql/clientFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        ...ClientQuery
      }
      order {
        id
        updatedAt
        uiState {
          enableAssign
        }
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${ClientFragments.query}
  ${orderEventFragments.query}
`;

class ClientUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { client, order } = this.props;
    return (
      <Mutation
        mutation={UPDATE_CLIENT}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(updateClient, { loading, error }) => (
          <ClientForm
            client={client}
            order={order}
            update={updateClient}
            errors={this.state.errors}
          />
        )}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
  };

  onCompleted = data => {
    this.props.toggleEdit();
  };
}

ClientUpdater.propTypes = {
  client: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired
};

export default ClientUpdater;

import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";
import commentFragments from "./../Queries/graphql/commentFragments";

const REJECT_ORDER = gql`
  mutation rejectOrder($id: ID!, $input: RejectOrderInput!) {
    rejectOrder(id: $id, input: $input) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
        comments {
          ...CommentQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${commentFragments.query}
`;

class RejectOrderUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation mutation={REJECT_ORDER} onError={this.onError}>
        {(update, { loading, error }) => {
          const rejectOrder = rejectedReason => {
            update({
              variables: {
                id: order.id,
                input: {
                  rejectedReason
                }
              }
            });
          };
          return render({ order, rejectOrder });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

RejectOrderUpdater.propTypes = {
  order: OrderObject,
  render: PropTypes.func.isRequired
};

export default RejectOrderUpdater;

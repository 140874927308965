import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import { formatPhone } from "../lib/Form/formatPhone";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.hcpsQuery}
        responseDataKey="healthcareProviders"
      />
    );
  }

  defaultColumnDefs = () => {
    return [
      {
        headerName: "Surname",
        field: "lastName",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridLink,
        cellRendererParams: params => {
          return {
            link: `/healthcare_providers/${params.data.id}?tab=details`,
            newTab: true
          };
        }
      },
      {
        headerName: "Given Name",
        field: "firstName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Active", "Inactive"]
        }
      },
      {
        headerName: "Contract",
        field: "contract",
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Healthcare Provider Group",
        field: "groupName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "AHPRA",
        field: "ahpraNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Medicare Provider Number",
        field: "medicareProviderNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Provider Type",
        field: "providerType",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "address",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "city",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Postcode",
        field: "postcode",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "state",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Phone #",
        field: "phoneNumber",
        filter: "agTextColumnFilter",
        valueGetter: params => formatPhone((params.data || {}).phoneNumber)
      },
      {
        headerName: "Mobile #",
        field: "mobileNumber",
        filter: "agTextColumnFilter",
        valueGetter: params => formatPhone((params.data || {}).mobileNumber)
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter"
      }
    ];
  };

  hcpsQuery = () => {
    return gql`
      query HealthcareProviders($agGridParams: RequestParamInput!) {
        healthcareProviders(agGridParams: $agGridParams) {
          id
          firstName
          lastName
          name
          groupName
          contract
          medicareProviderNumber
          providerType
          phoneNumber
          mobileNumber
          faxNumber
          preferredContactMethod
          email
          address
          city
          postcode
          state
          status
          ahpraNumber
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;

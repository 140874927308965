import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

class CreateGridFilterForUser extends React.Component {
  mutation = () => {
    const { grid } = this.props;
    return gql`
      mutation createGridFilterForUser($input: GridFilterInput!) {
        createGridFilterForUser(input: $input) {
          currentUser {
            id
            gridFilters(grid: "${grid}") {
              id
              name
              filterModel
            }
          }
        }
      }
    `;
  };

  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={this.mutation()}
        onError={this.onError}
      >
        {(update, { loading, error }) => {
          const createGridFilterForUser = input => {
            update({
              variables: {
                input: input
              }
            }).then(result => {
              this.props.onComplete(input.filterModel);
            });
          };
          return render({
            createGridFilterForUser: createGridFilterForUser
          });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

CreateGridFilterForUser.propTypes = {
  grid: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default CreateGridFilterForUser;

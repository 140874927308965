import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const ASSIGN_STAFF = gql`
  mutation assignStaffToOrder($id: ID!, $input: AssignStaffToOrderInput!) {
    assignStaffToOrder(id: $id, input: $input) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
        client {
          id
          name
          reference
          city
          state
          cardType
        }
        healthcareProvider {
          id
          name
        }
        partner {
          id
          name
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
`;

class AssignUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { staff, order, render } = this.props;
    return (
      <Mutation
        mutation={ASSIGN_STAFF}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => render({ staff, order, update })}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };

  onCompleted = data => {
    this.props.complete(data);
  };
}

AssignUpdater.propTypes = {
  order: PropTypes.object.isRequired,
  staff: PropTypes.object,
  render: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired
};

export default AssignUpdater;

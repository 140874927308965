import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const MUTATION = gql`
  mutation requestApproval($id: ID!) {
    requestApproval(id: $id) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
`;

class RequestApprovalUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation mutation={MUTATION} onError={this.onError}>
        {(update, { loading, error }) => {
          const requestApproval = () => {
            update({
              variables: {
                id: order.id
              }
            });
          };
          return render({ order, requestApproval });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
    this.props.onError(error);
  };
}

RequestApprovalUpdater.propTypes = {
  order: OrderObject,
  render: PropTypes.func.isRequired,
  onError: PropTypes.func
};

export default RequestApprovalUpdater;

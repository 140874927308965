import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "../Queries/OrderQuery";
import orderFragments from "../Queries/graphql/orderFragments";
import commentFragments from "../Queries/graphql/commentFragments";
import SendToGpBtn from "./SendToGpBtn";
import PropTypes from "prop-types";

const SEND_ORDER = gql`
  mutation sendInternalOrderToGp($id: ID!) {
    sendInternalOrderToGp(id: $id) {
      order {
        id
        ...OrderFields
        comments {
          ...CommentQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${commentFragments.query}
`;

class SendInternalOrderToGp extends React.Component {
  state = { errors: null };
  render() {
    const { order, btnText } = this.props;

    return (
      <Mutation mutation={SEND_ORDER} onError={this.onError}>
        {(update, { loading, error }) => (
          <SendToGpBtn order={order} update={update} btnText={btnText} />
        )}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

SendInternalOrderToGp.propTypes = {
  order: OrderObject.isRequired,
  btnText: PropTypes.string.isRequired
};

export default SendInternalOrderToGp;

import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

class RemoveFromPartnerBtn extends React.Component {
  state = {
    disabled: false
  };

  render() {
    return (
      <Mutation
        mutation={REMOVE_FROM_PARTNER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {update => (
          <button
            onClick={e => this.onClick(update)}
            className="button button--small red"
            disabled={this.state.disabled}
          >
            Remove from partner
          </button>
        )}
      </Mutation>
    );
  }

  onClick = update => {
    const { partnerId, params } = this.props;
    const user = params.data;
    if (
      window.confirm(
        "This will remove the user from this partner for all contracts, are you sure?"
      )
    ) {
      this.setState({ disabled: true });
      update({
        variables: {
          id: partnerId,
          userId: user.id
        }
      });
    }
  };

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
  };

  onCompleted = data => {
    this.props.params.api.refreshServerSide();
  };
}

RemoveFromPartnerBtn.propTypes = {
  partnerId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
};

export default RemoveFromPartnerBtn;

const REMOVE_FROM_PARTNER = gql`
  mutation removeUserFromPartner($id: ID!, $userId: ID!) {
    removeUserFromPartner(id: $id, userId: $userId) {
      userRemoved
    }
  }
`;

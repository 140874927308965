import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { OrderObject } from "../Queries/OrderQuery";
import orderFragments from "../Queries/graphql/orderFragments";
import orderLinesAndEvents from "../Queries/graphql/orderLinesAndEvents";

const SEND_SMS = gql`
  mutation sendSMSForOrder($id: ID!, $to: String!, $body: String!) {
    sendSmsForOrder(id: $id, to: $to, body: $body) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
`;

class SendSMSMutation extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation
        mutation={SEND_SMS}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const sendSMS = (to, body) => {
            update({
              variables: {
                id: order.id,
                to: to,
                body: body
              }
            });
          };
          return render({ sendSMS, errors: this.state.errors });
        }}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
    this.props.onError();
  };

  onCompleted = response => {
    this.props.onComplete();
  };
}

SendSMSMutation.propTypes = {
  order: OrderObject,
  onError: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default SendSMSMutation;

import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment PaymentQuery on Payment {
      id
      description
      amount
      date
      userName
      partnerReference
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.number,
    date: PropTypes.string,
    userName: PropTypes.string
  })
};

export default fragments;

import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";

const ProcessLinesQuery = ({ render, orderId, queryField, disableUIFunc }) => {
  const field = `${queryField}OrderLines`;
  return (
    <GraphqlWrapper>
      <Query
        query={gqlProcessLinesQuery(orderId, field)}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, refetch }) => {
          if (error) return <div>Error</div>;
          if (loading || (data && !data.order))
            return <div className="spinner" />;
          return render({
            orderLines: data.order[field]
          });
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlProcessLinesQuery = (id, field) => {
  return gql`
    query ProcessLines {
      order(id: ${id}) {
        id
        ${field} {
          id
          sku
          description
          rapScheduleCode
          saleType
          quantity
          status
          trialStatus
          trialStatusOptions
          assetDetails
        }
      }
    }
  `;
};

ProcessLinesQuery.propTypes = {
  orderId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
  queryField: PropTypes.string.isRequired
};

export default ProcessLinesQuery;

import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";
import orderLineFragments from "./../Queries/graphql/orderLineFragments";

import CancelBtn from "./CancelBtn";

const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(id: $id) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
        orderLines {
          ...OrderLineQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${orderLineFragments.query}
`;

class CancelOrder extends React.Component {
  state = { errors: null };
  render() {
    const { order } = this.props;
    return (
      <Mutation mutation={CANCEL_ORDER} onError={this.onError}>
        {(update, { loading, error }) => (
          <CancelBtn order={order} update={update} />
        )}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

CancelOrder.propTypes = {
  order: OrderObject
};

export default CancelOrder;

import React from "react";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";

import { contractIdFromPath } from "../lib/Util/url";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.costCentresQuery}
        responseDataKey="dischargeCostCentres"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Code",
      field: "code",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/contracts/${contractIdFromPath()}/discharge_cost_centres/${
            params.data.id
          }?tab=discharge_cost_centre`
        };
      }
    },
    {
      headerName: "Description",
      field: "description",
      filter: "agTextColumnFilter",
      width: 900
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"]
      }
    }
  ];

  costCentresQuery = () => {
    const contractId = contractIdFromPath();
    return gql`
      query DischargeCostCentres($agGridParams: RequestParamInput!) {
        dischargeCostCentres(contractId: ${contractId}, agGridParams: $agGridParams) {
          id
          code
          description
          status
        }
      }
    `;
  };
}

export default TableView;

import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment DocumentQuery on Document {
      id
      categoryName
      ownerName
      addedAt
      fileName
      fileSize
      fileUrl
      syncWithEpi
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    categoryName: PropTypes.string,
    ownerName: PropTypes.string,
    addedAt: PropTypes.string,
    fileName: PropTypes.string,
    fileSize: PropTypes.string,
    fileUrl: PropTypes.string,
    syncWithEpi: PropTypes.bool
  })
};

export default fragments;

import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import { formatPhone } from "../lib/Form/formatPhone";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.clientsQuery}
        responseDataKey="clients"
      />
    );
  }

  defaultColumnDefs = () => {
    return [
      {
        headerName: "Client Reference Number",
        field: "reference",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridLink,
        cellRendererParams: params => {
          return {
            link: `/clients/${params.data.id}?tab=details`,
            newTab: true
          };
        }
      },
      {
        headerName: "Contract",
        field: "contract",
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Given Name",
        field: "firstName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Last Name",
        field: "lastName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Phone Number",
        field: "phoneNumber",
        filter: "agTextColumnFilter",
        valueGetter: params => formatPhone((params.data || {}).phoneNumber)
      },
      {
        headerName: "Mobile Number",
        field: "mobileNumber",
        filter: "agTextColumnFilter",
        valueGetter: params => formatPhone((params.data || {}).mobileNumber)
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Alternative Contact Name",
        field: "altContactName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Alternative Contact Phone",
        field: "altPhoneNumber",
        filter: "agTextColumnFilter",
        valueGetter: params => formatPhone((params.data || {}).altPhoneNumber)
      },
      {
        headerName: "Card Type",
        field: "cardType",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Gold", "White"]
        }
      },
      {
        headerName: "Address",
        field: "address",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "city",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "state",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Postcode",
        field: "postcode",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Gender",
        field: "gender",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Male", "Female"]
        }
      },
      {
        headerName: "ACFI Classification",
        field: "acfiClassification",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Active", "Inactive", "Deleted"]
        }
      },
      {
        headerName: "Date of Birth",
        field: "dateOfBirth",
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      },
      {
        headerName: "Homecare Packages lvl 4",
        field: "homecarePackageLevelDesc",
        colId: "homecarePackageLevel",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["True", "False"]
        }
      },
      {
        headerName: "Residential Care Facility",
        field: "residentialCareFacilityDesc",
        colId: "residentialCareFacility",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["True", "False"]
        }
      },
      {
        headerName: "Funding Type",
        field: "fundingOtherType",
        colId: "fundingOtherType",
        filter: "agTextColumnFilter"
      }
    ];
  };

  clientsQuery = () => {
    return gql`
      query Clients($agGridParams: RequestParamInput!) {
        clients(agGridParams: $agGridParams) {
          id
          reference
          lastName
          firstName
          status
          gender
          dateOfBirth
          phoneNumber
          mobileNumber
          email
          altContactName
          altPhoneNumber
          cardType
          homecarePackageLevelDesc
          residentialCareFacilityDesc
          acfiClassification
          contract
          address
          postcode
          city
          state
          fundingOtherType
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;

import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../Queries/graphql/orderAllocationsFragments";

import ReinstateBtn from "./ReinstateBtn";

const REINSTATE_ORDER = gql`
  mutation reinstateOrder($id: ID!) {
    reinstateOrder(id: $id) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      newOrder {
        id
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
`;

class ReinstateOrder extends React.Component {
  state = { errors: null };
  render() {
    const { order } = this.props;
    return (
      <Mutation mutation={REINSTATE_ORDER} onError={this.onError}>
        {(update, { loading, error, data }) => {
          // when finished, take the user to the new order
          if (data && data.reinstateOrder && data.reinstateOrder.newOrder) {
            window.location = "/orders/" + data.reinstateOrder.newOrder.id;
          }
          return <ReinstateBtn order={order} update={update} />;
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

ReinstateOrder.propTypes = {
  order: OrderObject
};

export default ReinstateOrder;

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { contractUserIdFromPath } from "../../../lib/Util/url";

class ContractUserPartnerCheckbox extends React.Component {
  state = {
    checked: this.props.row.data.userIsAssigned
  };
  render() {
    const rowData = this.props.row.data;
    return (
      <Mutation
        mutation={TOGGLE_PARTNER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {update => (
          <React.Fragment>
            <input
              type="checkbox"
              id={`contract-np-user-${rowData.id}`}
              checked={this.state.checked}
              onChange={() => this.send(update, rowData.id)}
              style={{ marginRight: 5 }}
            />
            <label
              htmlFor={`contract-np-user-${rowData.id}`}
              style={{ cursor: "pointer" }}
            >
              Assigned to Partner
            </label>
          </React.Fragment>
        )}
      </Mutation>
    );
  }

  send = (update, networkPartnerId) => {
    update({
      variables: {
        id: contractUserIdFromPath(),
        networkPartnerId: networkPartnerId
      }
    });
  };

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
  };

  onCompleted = data => {
    this.setState({ checked: data.toggleUserContractPartner.userIsAssigned });
    const newRowData = {
      ...this.props.row.data,
      userIsAssigned: data.toggleUserContractPartner.userIsAssigned
    };
    this.props.row.node.setData(newRowData);
  };
}

ContractUserPartnerCheckbox.propTypes = {
  row: PropTypes.object.isRequired
};

export default ContractUserPartnerCheckbox;

const TOGGLE_PARTNER = gql`
  mutation toggleUserContractPartner($id: ID!, $networkPartnerId: ID!) {
    toggleUserContractPartner(id: $id, networkPartnerId: $networkPartnerId) {
      userIsAssigned
    }
  }
`;

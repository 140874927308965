import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";
import documentFragments from "./../../Queries/graphql/documentFragments";
import commentFragments from "./../../Queries/graphql/commentFragments";
import partnerFragments from "./../../Queries/graphql/partnerFragments";

class PartnerSender extends React.Component {
  render() {
    const { order, render } = this.props;
    return (
      <GraphqlWrapper>
        <Mutation mutation={SEND_TO_PARTNER} onCompleted={this.onCompleted}>
          {(update, { loading, error }) => {
            const sendToPartner = () => {
              update({
                variables: {
                  id: order.id
                }
              });
            };
            return render({ order, sendToPartner });
          }}
        </Mutation>
      </GraphqlWrapper>
    );
  }
  onCompleted = data => {
    if (data.sendOrderToPartner.orderInaccessible) {
      window.alert(
        "Order sent to branch but you do not have access to the branch. Please ensure you have notified the branch of this order.\n" +
          "You will now be redirected back to the orders page."
      );
      window.location.href = "/";
    }
  };
}

PartnerSender.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default PartnerSender;

const SEND_TO_PARTNER = gql`
  mutation sendOrderToPartner($id: ID!) {
    sendOrderToPartner(id: $id) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
        documents {
          ...DocumentQuery
        }
        comments {
          ...CommentQuery
        }
        partner {
          ...PartnerQuery
        }
      }
      errors {
        path
        message
      }
      orderInaccessible
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
  ${documentFragments.query}
  ${commentFragments.query}
  ${partnerFragments.query}
`;

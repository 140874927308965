import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment OrderUiFields on Order {
      uiState {
        showFacility
        showClientAndHcp
        showContractPricing
        showNpPricing
        showStep1
        showStep2
        processStep2Message
        showSeekApproval
        enableAssign
        showAssignBtn
        showPreferredPartnerBtn
        showSplitOrderBtn
        showConvertToOrderBtn
        showAssignToMeBtn
        showReassignBtn
        showReassignLinesBtn
        showCopyOrderBtn
        allowCompleteReassignLines
        showCancelOrderBtn
        showReinstateBtn
        showResendSyncingBtn
        showResendInvoiceBtn
        showFulfillment
        showAcceptReject
        showRequestApproval
        showPartnerApproval
        showPartnerApprovalCompleted
        showInvoicingBtn
        showPricingDiffMsg
        showProcessDelivered
        showProcessPickupRequested
        showRollbackPickupRequest
        showProcessReturned
        showProcessTransfer
        showTrials
        showUpdateTrials
        showEnableTrialDelivery
        showTrialGpSync
        showAwaitingCall
        showProcessShipments
        allowProcessShipments
        allowReverseInvoice
        stagePrevalidation
        stageAssignment
        showAssignment
        stageAssignAsPartner
        stageCanSendToPartner
        stageAssigned
        editable
        documentsEditable
        hasSalesRep
        showDebtor
        canEditDebtor
        canEditLines
        canEditLinePrices
        allowOrderConfirmation
        canEditHireEnd
        showAddRepairLineBtn
        showAddHomeModsLineBtn
        showPaymentsBtn
        showSendClientSmsBtn
        allowProcessPayment1
        allowProcessPracticalCompletion
        orderOverpaid
        showPrintDeliveryDocket
        showPrintOrderForm
        showPrintPurchaseOrder
        goBackToValidationBtnDisabled
        showGoBackToValidationBtn
        allowEditHcpAfterGpSync
        allowEditOrderAfterGpSync
        allowEditCostCentre
        allowEditFacility
        allowEditWardClient
        allowEditComplexHomeMods
        allowEditCpo
        payment1Processed
        payment2Processed
        showProcessingInstructions
        showCheckStockButton
        showCheckFreightButton
        allowAddFreight
        currentUserIsHeadOffice
      }
    }
  `,
  object: PropTypes.shape({
    showFacility: PropTypes.bool,
    showClientAndHcp: PropTypes.bool,
    showContractPricing: PropTypes.bool,
    showNpPricing: PropTypes.bool,
    showStep1: PropTypes.bool,
    showStep2: PropTypes.bool,
    processStep2Message: PropTypes.string,
    showAssignBtn: PropTypes.bool,
    showPreferredPartnerBtn: PropTypes.bool,
    showSeekApproval: PropTypes.bool,
    showConvertToOrderBtn: PropTypes.bool,
    showAssignToMeBtn: PropTypes.bool,
    showReassignBtn: PropTypes.bool,
    showReassignLinesBtn: PropTypes.bool,
    showCopyOrderBtn: PropTypes.bool,
    allowCompleteReassignLines: PropTypes.bool,
    showCancelOrderBtn: PropTypes.bool,
    showReinstateBtn: PropTypes.bool,
    showResendSyncingBtn: PropTypes.bool,
    showResendInvoiceBtn: PropTypes.bool,
    showFulfillment: PropTypes.bool,
    showAcceptReject: PropTypes.bool,
    showRequestApproval: PropTypes.bool,
    showPartnerApproval: PropTypes.bool,
    showPartnerApprovalCompleted: PropTypes.bool,
    showProcessPickupRequested: PropTypes.bool,
    showRollbackPickupRequest: PropTypes.bool,
    showProcessTransfer: PropTypes.bool,
    showTrials: PropTypes.bool,
    showUpdateTrials: PropTypes.bool,
    showEnableTrialDelivery: PropTypes.bool,
    showTrialGpSync: PropTypes.bool,
    showAwaitingCall: PropTypes.bool,
    showProcessShipments: PropTypes.bool,
    allowProcessShipments: PropTypes.bool,
    stagePrevalidation: PropTypes.bool,
    stageAssignment: PropTypes.bool,
    showAssignment: PropTypes.bool,
    stageAssignAsPartner: PropTypes.bool,
    stageCanSendToPartner: PropTypes.bool,
    stageAssigned: PropTypes.bool,
    editable: PropTypes.bool,
    canEditLines: PropTypes.bool,
    canEditLinePrices: PropTypes.bool,
    allowOrderConfirmation: PropTypes.bool,
    canEditHireEnd: PropTypes.bool,
    showAddRepairLineBtn: PropTypes.bool,
    showAddHomeModsLineBtn: PropTypes.bool,
    showPaymentsBtn: PropTypes.bool,
    showSendClientSmsBtn: PropTypes.bool,
    allowProcessPayment1: PropTypes.bool,
    allowProcessPracticalCompletion: PropTypes.bool,
    orderOverpaid: PropTypes.bool,
    showPrintDeliveryDocket: PropTypes.bool,
    showPrintPurchaseOrder: PropTypes.bool,
    showPrintOrderForm: PropTypes.bool,
    showGoBackToValidationBtn: PropTypes.bool,
    goBackToValidationBtnDisabled: PropTypes.bool,
    allowEditHcpAfterGpSync: PropTypes.bool,
    allowEditOrderAfterGpSync: PropTypes.bool,
    allowEditCostCentre: PropTypes.bool,
    allowEditFacility: PropTypes.bool,
    allowEditWardClient: PropTypes.bool,
    allowEditComplexHomeMods: PropTypes.bool,
    allowEditCpo: PropTypes.bool,
    payment1Processed: PropTypes.bool,
    payment2Processed: PropTypes.bool,
    showProcessingInstructions: PropTypes.bool,
    showCheckStockButton: PropTypes.bool,
    showCheckFreightButton: PropTypes.bool,
    allowAddFreight: PropTypes.bool,
    currentUserIsHeadOffice: PropTypes.bool
  })
};

export default fragments;

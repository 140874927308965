import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import { contractIdFromPath } from "../lib/Util/url";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.networkPartnersQuery}
        responseDataKey="networkPartners"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Network Partner Name",
      field: "name",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/network_partners/${params.data.id}?tab=details`,
          newTab: true
        };
      }
    },
    {
      headerName: "Type",
      field: "partnerType",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Network", "Internal"]
      }
    },
    {
      headerName: "GP Supplier Code",
      field: "gpSupplierCode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Services Offered",
      field: "serviceDesc",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Hire", "Home Mod", "Repair", "Supply"]
      }
    },
    {
      headerName: "Contracts",
      colId: "contract",
      field: "contracts",
      // Doesn't make sense to sort when there are multiple
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Address",
      field: "streetAddress",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Postcode",
      field: "postcode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    },
    {
      headerName: "Customer Service Contact",
      field: "custServiceRepFullName",
      filter: false,
      sortable: false
    },
    {
      headerName: "Phone #",
      field: "custServiceRepPhoneNumber",
      filter: false,
      sortable: false
    },
    {
      headerName: "Mobile #",
      field: "custServiceRepMobileNumber",
      filter: false,
      sortable: false
    },
    {
      headerName: "Email",
      field: "custServiceRepEmail",
      filter: false,
      sortable: false
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Active", "Draft", "Inactive"]
      }
    },
    {
      headerName: "GP Document ID",
      field: "gpDocumentId",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Sales Rep",
      field: "salesRepCode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Using CMP",
      field: "usingCmp",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    }
  ];

  networkPartnersQuery = () => {
    const contractId = contractIdFromPath();
    return gql`
      query NetworkPartners($agGridParams: RequestParamInput!) {
        networkPartners(agGridParams: $agGridParams, contractId:${contractId}) {
          id
          name
          streetAddress
          custServiceRepFullName
          custServiceRepPhoneNumber
          custServiceRepMobileNumber
          custServiceRepEmail
          status
          partnerType
          gpSupplierCode
          city
          state
          postcode
          serviceDesc
          contracts
          gpDocumentId
          salesRepCode
          usingCmp
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;

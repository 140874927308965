import React from "react";
import PropTypes from "prop-types";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import partnerFragments from "./../../Queries/graphql/partnerFragments";

const PartnerQuery = ({ contractId, render }) => {
  return (
    <GraphqlWrapper>
      <Query query={gqlPartnerQuery(parseInt(contractId))}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :(</p>;
          return render({ partners: data.partners });
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlPartnerQuery = contractId => {
  return gql`
    query Partners {
      partners(contractId: ${contractId}) {
        ...PartnerQuery
      }
    }
    ${partnerFragments.query}
  `;
};

PartnerQuery.propTypes = {
  contractId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default PartnerQuery;

import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";

const MUTATION = gql`
  mutation updateInvoiceLine($id: ID!, $input: InvoiceLineInput!) {
    updateInvoiceLine(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
`;

class InvoiceLineUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { children } = this.props;
    return (
      <Mutation mutation={MUTATION} onError={this.onError}>
        {(update, { loading, error }) => {
          const updateInvoiceLine = (id, input) => {
            update({
              variables: {
                id,
                input: { id, ...this.whitelistedInput(input) }
              }
            });
          };
          return children({ updateInvoiceLine });
        }}
      </Mutation>
    );
  }

  whitelistedInput = line => {
    const input = {};
    const whitelist = ["partnerReference"];

    whitelist.map(key => {
      if (line[key] !== undefined) {
        input[key] = line[key];
      }
    });
    return input;
  };

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

InvoiceLineUpdater.propTypes = {
  children: PropTypes.func.isRequired
};

export default InvoiceLineUpdater;

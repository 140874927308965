import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import {apolloClient} from "../../lib/GraphQL/GraphqlWrapper";

import CommentsCounter from "./../Comments/CommentsCounter";
import CmpAgGridOrderLink from "../../lib/AgGrid/CmpAgGridOrderLink";
import {boolFieldYesNo} from "./../BusRules/orders";
import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import dateFuncs from "../../lib/Form/dateFuncs";
import StatusCellRenderer from "./StatusCellRenderer";

class TableView extends React.Component {
  state = {
    completedStatusDesc: [
      "All Assigned",
      "Completed",
      "Cancelled",
      "Quote Rejected"
    ]
  };
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.ordersQuery}
        responseDataKey="orders"
        pollingPathNameMatch={/^\/$/}
        defaultSortModel={this.defaultSortModel()}
        frameworkComponents={this.frameworkComponents}
        filterButtons={this.filterButtons()}
        secondaryFilterButtons={this.secondaryFilterButtons()}
        userFilters={this.props.currentUser.gridFilters}
        rowHeight={30}
      />
    );
  }

  filterButtons = () => {
    let buttons = [
      {
        buttonText: "Active Orders",
        filterModel: {
          status: {
            filterType: "set",
            values: this.props.currentUser.orderStatusDescriptions.filter(
              status => !this.state.completedStatusDesc.includes(status)
            )
          }
        }
      },
      {
        buttonText: "Ready To Invoice",
        countQueryField: "readyToInvoiceOrdersCount",
        filterModel: {
          readyToInvoice: {
            filterType: "set",
            values: ["Yes"]
          }
        }
      },
      {
        buttonText: "Completed Orders",
        filterModel: {
          status: {
            filterType: "set",
            values: this.state.completedStatusDesc
          }
        }
      }
    ];
    if (this.props.currentUser.aidacareHo) {
      buttons.push(
        ...[
          {
            buttonText: "Vic Hire Approaching 30 Days",
            countQueryField: "vicHireNear30DaysCount",
            hideOnZeroCount: true,
            filterModel: {
              vicHireNear30Days: {
                filterType: "set",
                values: ["Yes"]
              }
            }
          },
          {
            buttonText: "Shipment Required",
            countQueryField: "shipmentRequiredCount",
            hideOnZeroCount: true,
            filterModel: {
              shipmentRequired: {
                filterType: "set",
                values: ["Yes"]
              }
            }
          }
        ]
      );
    }
    return buttons;
  };

  secondaryFilterButtons = () => {
    let buttons = [];
    if (this.props.currentUser.aidacareHo) {
      buttons.push({
        buttonText: "My Pending Validation",
        filterModel: {
          assignedStaffName: {
            filterType: "set",
            values: [this.props.currentUser.name]
          },
          status: {
            filterType: "set",
            values: ["Pending Validation"]
          }
        }
      });
    }
    buttons.push({
      buttonText: "My Unread Messages",
      countQueryField: "myUnreadCount",
      filterModel: {
        myUnread: {
          filterType: "set",
          values: ["Yes"]
        }
      }
    });
    if (this.props.currentUser.headOfficeContracts.includes("DVA")) {
      buttons.push({
        buttonText: "My Complex Home Mods",
        filterModel: {
          assignedStaffName: {
            filterType: "set",
            values: [this.props.currentUser.name]
          },
          orderType: {
            filterType: "set",
            values: ["Home Mod"]
          },
          complexHomeMods: {
            filterType: "set",
            values: ["Yes"]
          }
        }
      });
    }
    if (this.props.currentUser.aidacareHo && this.props.currentUser.partner) {
      buttons.push(
        ...[
          {
            buttonText: "My H.O. orders",
            filterModel: {
              contract: {
                filterType: "set",
                values: this.props.currentUser.headOfficeContracts
              }
            }
          },
          {
            buttonText: "My Branch orders",
            filterModel: {
              contract: {
                filterType: "set",
                values: this.props.currentUser.partnerContracts
              }
            }
          }
        ]
      );
    }
    return buttons;
  };

  frameworkComponents = {
    statusCellRenderer: params => {
      return <StatusCellRenderer params={params} />;
    },
    commentsCellRenderer: row => {
      return <CommentsCounter order={row.data} />;
    }
  };

  defaultSortModel = () => [
    {
      colId: "id",
      sort: "desc"
    }
  ];

  defaultColumnDefs = () => {
    let cols = [
      {
        headerName: "CMP Order #",
        field: "cmpOrderNumber",
        colId: "id",
        width: 120,
        sort: "desc",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridOrderLink,
        cellRendererParams: params => {
          return {
            link: `/orders/${params.data.id}`
          };
        }
      },
      {
        headerName: "Web Order #",
        field: "webOrderNo",
        colId: "webOrderId",
        width: 120,
        pinned: "left",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Contract",
        field: "contract.contractCode",
        colId: "contract",
        width: 120,
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Status",
        field: "statusDesc",
        colId: "status",
        cellRenderer: "statusCellRenderer",
        valueGetter: params => (params.data || {}).statusDesc,
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.currentUser.orderStatusDescriptions,
          excelMode: "windows",
          suppressSorting: true
        }
      },
      {
        headerName: "Type",
        field: "orderType",
        width: 120,
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          values: ["Buy", "Hire", "Home Mod", "Repair", "RTO"]
        }
      },
      {
        headerName: "Order Date",
        field: "orderDate",
        colId: "dateReceived",
        width: 180,
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      },
      {
        headerName: "CPO",
        field: "cpo",
        width: 120,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Client Ref. #",
        field: "client.reference",
        colId: "clientReference",
        width: 150,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Card Type",
        field: "client.cardType",
        colId: "clientCardType",
        width: 120,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          values: ["Gold", "White"]
        }
      },
      {
        headerName: "Client",
        field: "client.name",
        colId: "clientName",
        width: 200,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "client.city",
        colId: "clientCity",
        width: 200,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "client.state",
        colId: "clientState",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Facility",
        field: "facility.name",
        colId: "facilityName",
        width: 200,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Debtor",
        field: "debtor.code",
        colId: "debtor",
        width: 200,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Network Partner",
        field: "partner.name",
        colId: "partnerName",
        width: 240,
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["contains", "startsWith", "notContains"]
        }
      },
      {
        headerName: "Healthcare Provider",
        field: "healthcareProvider.name",
        colId: "healthcareProviderName",
        width: 240,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Staff",
        field: "assignedStaffName",
        width: 200,
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.staffFilterValues(this.props.staffMembers),
          excelMode: "windows"
        },
        valueSetter: params => this.updateStaffMember(params),
        editable: params => params.data.uiState.currentUserIsHeadOffice,
        cellEditor: "agRichSelectCellEditor",
        cellEditorPopup: true,
        cellEditorParams: params => {
          return {
            values: this.props.staffMembers
              .filter(staff =>
                staff.headOfficeContractIds.includes(params.data.contract.id)
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(staff => staff.name)
          };
        }
      },
      {
        headerName: "Msges",
        colId: "comments",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          defaultToNothingSelected: true,
          values: ["Unread only"]
        },
        cellRenderer: "commentsCellRenderer",
        valueGetter: params => ({
          total: (params.data || {}).commentsCount,
          unread: (params.data || {}).unreadCommentsCount
        })
      },
      {
        headerName: "Urgent",
        field: "urgent",
        width: 70,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        cellRenderer: row => {
          return (
            <span
              className={`${row.data.urgent ? " label label--urgent" : ""}`}
            >
              {boolFieldYesNo(row.data.urgent)}
            </span>
          );
        },
        valueGetter: params => (params.data || {}).urgent
      },
      {
        headerName: "Awaiting Call",
        field: "awaitingCall",
        width: 70,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        cellRenderer: row => {
          return (
            <span
              className={`${
                row.data.awaitingCall ? " label label--urgent" : ""
              }`}
            >
              {boolFieldYesNo(row.data.awaitingCall)}
            </span>
          );
        },
        valueGetter: params => (params.data || {}).awaitingCall
      },
      {
        headerName: "Followup",
        field: "followUp",
        width: 180,
        valueGetter: params => {
          const uiState = (params.data || {}).uiState;
          if (uiState) {
            return uiState.currentUserIsHeadOffice
              ? params.data.followUpOn
              : params.data.npFollowUpOn;
          }
        },
        cellRenderer: row => {
          if (row.value) {
            return dateFuncs.displayDateYYYY(row.value);
          }
          return null;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      },
      {
        headerName: "Hire Start Date",
        field: "hireStartOn",
        width: 180,
        cellRenderer: row => {
          if (row.data.hireStartOn) {
            return dateFuncs.displayDateYYYY(row.data.hireStartOn);
          }
          return null;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      },
      {
        headerName: "Vic 30 Day Discharge",
        field: "vic30DayDischarge",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        valueGetter: params =>
          boolFieldYesNo((params.data || {}).vic30DayDischarge)
      },
      {
        headerName: "Hospital Type",
        field: "hospitalType",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          suppressSorting: true,
          values: ["Discharge", "Ward", "NA"]
        }
      },
      {
        headerName: "Complex Home Mod",
        field: "complexHomeMods",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        valueGetter: params =>
          boolFieldYesNo((params.data || {}).complexHomeMods)
      },
      {
        headerName: "Updated On",
        field: "syncedAttrUpdatedAt",
        width: 180,
        cellRenderer: row => {
          if (row.data.syncedAttrUpdatedAt) {
            return dateFuncs.displayDateYYYY(row.data.syncedAttrUpdatedAt);
          }
          return null;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      },
      {
        headerName: "Originated",
        field: "sourceSystem",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          values: ["ERAP", "EPI", "Manual", "CMP", "NED"]
        }
      },
      {
        headerName: "Consignment",
        field: "consignment",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        valueGetter: params => boolFieldYesNo((params.data || {}).consignment)
      },
      {
        headerName: "Trial",
        field: "trial",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        valueGetter: params => boolFieldYesNo((params.data || {}).trial)
      }
    ];
    if (this.props.currentUser.aidacareHo) {
      cols.push(
        ...[
          {
            headerName: "Manual Order",
            field: "manualOrder",
            width: 100,
            filter: "agSetColumnFilter",
            filterParams: {
              suppressSelectAll: true,
              suppressMiniFilter: true,
              values: ["Yes", "No"]
            },
            valueGetter: params =>
              boolFieldYesNo((params.data || {}).manualOrder)
          },
          {
            headerName: "Admin Notes",
            field: "adminNotes",
            width: 240,
            filter: "agTextColumnFilter",
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ["contains", "startsWith", "notContains"]
            },
            editable: true,
            valueSetter: params => this.updateAdminNotes(params)
          }
        ]
      );
    }
    cols.push(
      ...[
        {
          // Hidden column used by the Ready To Invoice filter button
          headerName: "Ready To Invoice",
          field: "readyToInvoice",
          sortable: false,
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          filterParams: {
            suppressMiniFilter: true,
            values: ["Yes", "No"]
          }
        },
        {
          // Hidden column used by the Vic Hire Approaching 30 Days filter button
          headerName: "Vic Hire Approaching 30 Days",
          field: "vicHireNear30Days",
          sortable: false,
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          filterParams: {
            suppressMiniFilter: true,
            values: ["Yes", "No"]
          }
        },
        {
          // Hidden column used by the Shipment Required filter button
          headerName: "Shipment Required",
          field: "shipmentRequired",
          sortable: false,
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          filterParams: {
            suppressMiniFilter: true,
            values: ["Yes", "No"]
          }
        },
        {
          // Hidden column used by the My Unread Messages filter button
          headerName: "My Unread Messages",
          field: "myUnread",
          sortable: false,
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          filterParams: {
            suppressMiniFilter: true,
            values: ["Yes", "No"]
          }
        }
      ]
    );
    return cols;
  };

  staffFilterValues = staffMembers => {
    let staffNames = staffMembers.map(staff => staff.name);
    staffNames.push("--unassigned--");
    // Add current user if HO and not in the list so the secondary filters work
    if (
      this.props.currentUser.aidacareHo &&
      !staffNames.includes(this.props.currentUser.name)
    ) {
      staffNames.push(this.props.currentUser.name);
    }
    return staffNames;
  };

  ordersQuery = () => {
    return gql`
      query Orders($agGridParams: RequestParamInput!) {
        orders(agGridParams: $agGridParams) {
          id
          cmpOrderNumber
          webOrderNo
          orderType
          orderDate
          dateReceived
          vic30DayDischarge
          updatedAt
          syncedAttrUpdatedAt
          status
          statusDesc
          urgent
          awaitingCall
          assignedStaffId
          assignedStaffName
          hireStartOn
          commentsCount
          unreadCommentsCount
          followUpOn
          npFollowUpOn
          cpo
          hospitalType
          complexHomeMods
          manualOrder
          sourceSystem
          adminNotes
          consignment
          trial
          uiState {
            currentUserIsHeadOffice
          }
          contract {
            id
            contractCode
          }
          client {
            id
            name
            reference
            city
            state
            cardType
          }
          healthcareProvider {
            id
            name
          }
          facility {
            id
            name
          }
          partner {
            id
            name
          }
          debtor {
            id
            code
          }
        }
        readyToInvoiceOrdersCount
        vicHireNear30DaysCount
        shipmentRequiredCount
        myUnreadCount
      }
    `;
  };

  updateStaffMember = params => {
    const selectedStaff = this.props.staffMembers.find(
      staff => staff.name === params.newValue
    );
    if (selectedStaff) {
      const ASSIGN_STAFF = gql`
        mutation assignStaffToOrder(
          $id: ID!
          $input: AssignStaffToOrderInput!
        ) {
          assignStaffToOrder(id: $id, input: $input) {
            order {
              status
              statusDesc
              assignedStaffId
              assignedStaffName
            }
          }
        }
      `;
      apolloClient
        .mutate({
          mutation: ASSIGN_STAFF,
          variables: {
            id: params.data.id,
            input: {
              userId: selectedStaff.id
            }
          }
        })
        .then(result => {
          params.node.setData({
            ...params.data,
            ...result.data.assignStaffToOrder.order
          });
        })
        .catch(error => {
          console.log("Error occurred");
          console.log(error);
        });
    }
    return false;
  };

  updateAdminNotes = params => {
    const UPDATE_ADMIN_NOTES = gql`
      mutation updateOrder($id: ID!, $input: OrderInput!) {
        updateOrder(id: $id, input: $input) {
          order {
            id
            adminNotes
          }
        }
      }
    `;
    apolloClient
      .mutate({
        mutation: UPDATE_ADMIN_NOTES,
        variables: {
          id: params.data.id,
          input: {
            adminNotes: params.newValue
          }
        }
      })
      .then(result => {
        params.node.setData({
          ...params.data,
          ...result.data.updateOrder.order
        });
      })
      .catch(error => {
        console.log("Error occurred");
        console.log(error);
      });
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  staffMembers: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default TableView;

import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

const AidacareStaffQuery = ({ orderId, render }) => {
  return (
    <Query query={gqlAidacareStaffQuery(orderId)}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
        return render({ staffMembers: data.order.assignableStaff });
      }}
    </Query>
  );
};

const gqlAidacareStaffQuery = orderId => {
  return gql`
    query AssignableStaff {
      order(id: ${orderId}) {
        id
        assignableStaff {
          id
          name
        }
      }
    }
  `;
};

AidacareStaffQuery.propTypes = {
  orderId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default AidacareStaffQuery;

import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

const FacilitiesQuery = ({ contractId, render }) => (
  <Query query={gqlFacilitiesQuery(contractId)} fetchPolicy="cache-and-network">
    {({ loading, error, data }) => {
      if (!data.facilities) return <p>Loading...</p>;
      const sortedFacilities = data.facilities.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return render({ facilities: sortedFacilities });
    }}
  </Query>
);

const gqlFacilitiesQuery = contractId => {
  return gql`
    query Facilities {
      facilities(contractId: ${contractId}, activeOnly: true, excludeDrafts: true) {
        id
        name
        costCentre
        sourceId
        debtor
      }
    }
  `;
};

FacilitiesQuery.propTypes = {
  contractId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default FacilitiesQuery;

import React from "react";
import PropTypes from "prop-types";

import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import GraphqlWrapper, { apolloClient } from "GraphQL/GraphqlWrapper";
import processingInvoiceLines from "../Queries/graphql/processingInvoiceLines";

const InvoiceableLinesQuery = ({ render, reverseable, orderId }) => {
  return (
    <GraphqlWrapper>
      <Query
        query={gqlInvoiceableLinesQuery(orderId, reverseable)}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, refetch }) => {
          if (error) return <div>Error</div>;
          if (loading || (data && !data.order)) return <div>Loading...</div>;
          let dataLines =
            data.order.invoiceableLines || data.order.reversibleLines;
          const cachedProcessingInvoiceLines = apolloClient.readFragment({
            id: processingInvoiceLines.cacheFragmentId,
            fragment: processingInvoiceLines.query
          });
          if (cachedProcessingInvoiceLines) {
            dataLines = dataLines.filter(
              line =>
                !cachedProcessingInvoiceLines.processingInvoiceLineIds.includes(
                  line.id
                )
            );
          }

          return render({
            invoiceLines: dataLines
          });
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlInvoiceableLinesQuery = (id, reverseable) => {
  const whichLines = reverseable ? "reversibleLines" : "invoiceableLines";
  return gql`
    query ${whichLines} {
      order(id: ${id}) {
        id
        ${whichLines} {
          id
          status
          statusDesc
          description
          partnerReference
          periodDesc
          lineBuyUnitPriceExc
          lineBuyUnitPriceInc
          lineSellUnitPriceExc
          sellUnitPriceExc
          buyUnitPriceExc
          buyUnitPriceInc
          orderLine {
            id
            quantity
            description
            saleType
            size
            sku
            status
            deliveredOn
            pickedUpOn
          }
        }
      }
    }
  `;
};

InvoiceableLinesQuery.propTypes = {
  orderId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
  reverseable: PropTypes.bool.isRequired
};

export default InvoiceableLinesQuery;

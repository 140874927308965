import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "../Queries/graphql/orderLinesAndEvents";

import commentFragments from "./../Queries/graphql/commentFragments";
import CreatePaymentBtns from "./CreatePaymentBtns";

const CREATE_PAYMENT = gql`
  mutation createPayment(
    $id: ID!
    $number: String!
    $partnerReference: String!
    $date: String
  ) {
    createPaymentForOrder(
      id: $id
      number: $number
      partnerReference: $partnerReference
      date: $date
    ) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        comments {
          ...CommentQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${commentFragments.query}
`;

class CreatePayment extends React.Component {
  state = { errors: null };
  render() {
    const { order } = this.props;
    return (
      <Mutation mutation={CREATE_PAYMENT} onError={this.onError}>
        {(update, { loading, error }) => (
          <CreatePaymentBtns order={order} update={update} />
        )}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
  };
}

CreatePayment.propTypes = {
  order: OrderObject
};

export default CreatePayment;

import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";
import orderLineFragments from "./../Queries/graphql/orderLineFragments";

import CopyOrderBtn from "./../CopyOrderBtn";

const COPY_ORDER = gql`
  mutation copyOrder($id: ID!) {
    copyOrder(id: $id) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
        orderLines {
          ...OrderLineQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
  ${orderLineFragments.query}
`;

class CancelOrder extends React.Component {
  state = { errors: null };
  render() {
    const { order } = this.props;
    return (
      <Mutation
        mutation={COPY_ORDER}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => (
          <CopyOrderBtn order={order} update={update} />
        )}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };

  onCompleted = response => {
    window.location.href = "/orders/" + response.copyOrder.order.id;
  };
}

CancelOrder.propTypes = {
  order: OrderObject
};

export default CancelOrder;

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

const SEND_TO_GP_GQL = gql`
  mutation sendToGp($id: ID!, $model: String!, $flow: String!) {
    sendToGp(id: $id, model: $model, flow: $flow) {
      syncItem {
        id
        type
        flow
        orderId
        gpInvoiceNumber
        lastSyncAt
        enqueued
        resendable
        lastSyncError
        numberOfSyncAttempts
        closeable
        syncId
        lastSyncLink
      }
      errors {
        path
        message
      }
    }
  }
`;

class SendToGp extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={SEND_TO_GP_GQL}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const sendToGp = (row, onComplete) => {
            update({
              variables: {
                id: row.data.id,
                model: row.data.type,
                flow: row.data.flow
              }
            }).then(result => {
              if (result && result.data) {
                onComplete(row, result.data.sendToGp.syncItem);
              }
            });
          };
          return render({
            sendToGp
          });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

SendToGp.propTypes = {
  render: PropTypes.func.isRequired
};

export default SendToGp;

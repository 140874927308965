import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment ProcessingInvoiceLines on Order {
      processingInvoiceLineIds
    }
  `,
  cacheFragmentId: "currentlyProcessingInvoiceLines"
};

export default fragments;

import React from "react";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";

import { contractIdFromPath } from "../lib/Util/url";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.debtorsQuery}
        responseDataKey="debtors"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Code",
      field: "code",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/contracts/${contractIdFromPath()}/debtors/${
            params.data.id
          }?tab=debtor`
        };
      }
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"]
      }
    },
    {
      headerName: "Bulk Pricing",
      field: "bulkPricing",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    },
    {
      headerName: "Notes",
      field: "notes",
      filter: "agTextColumnFilter",
      width: 900
    }
  ];

  debtorsQuery = () => {
    const contractId = contractIdFromPath();
    return gql`
      query Debtors($agGridParams: RequestParamInput!) {
        debtors(contractId: ${contractId}, agGridParams: $agGridParams) {
          id
          code
          status
          bulkPricing
          notes
        }
      }
    `;
  };
}

export default TableView;

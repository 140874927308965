import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

const closeableRow = row => {
  return !!row.closeable;
};

const resendableRow = row => {
  return !!row.resendable;
};

class CloseButton extends React.Component {
  state = {
    isSending: false
  };

  render() {
    const { row } = this.props;

    return (
      closeableRow(row.data) &&
      resendableRow(row.data) && (
        <Mutation mutation={CLOSE_SYNC} onError={this.onError}>
          {(update, { loading, error }) => (
            <button
              onClick={e => {
                e.preventDefault();
                this.send(update);
              }}
              className="button button--small"
              disabled={this.state.isSending}
              style={{ marginRight: 5 }}
            >
              {this.state.isSending ? " Closing..." : "Close"}
            </button>
          )}
        </Mutation>
      )
    );
  }

  send = update => {
    const { row, onComplete } = this.props;

    this.setState({ isSending: true });
    update({
      variables: {
        id: row.data.syncId,
        model: row.data.type,
        flow: row.data.flow
      }
    }).then(result => {
      if (result && result.data) {
        onComplete(row, result.data.closeSync.syncItem);
      }
    });
  };
}

const CLOSE_SYNC = gql`
  mutation closeSync($id: ID!, $model: String!, $flow: String!) {
    closeSync(id: $id, model: $model, flow: $flow) {
      syncItem {
        id
        type
        flow
        orderId
        gpInvoiceNumber
        lastSyncAt
        enqueued
        resendable
        lastSyncError
        numberOfSyncAttempts
        closeable
        syncId
        lastSyncLink
      }
      errors {
        path
        message
      }
    }
  }
`;

CloseButton.propTypes = {
  row: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default CloseButton;
export { closeableRow };

import React from "react";

import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { OrderObject } from "../Queries/OrderQuery";
import SendToGpBtn from "./SendToGpBtn";
import PropTypes from "prop-types";

const RESEND_ORDER_INVOICE = gql`
  mutation resendOrderInvoice($id: ID!) {
    resendOrderInvoice(id: $id) {
      order {
        id
      }
    }
  }
`;

class ResendOrderInvoice extends React.Component {
  state = { errors: null };
  render() {
    const { order, btnText } = this.props;
    return (
      <Mutation mutation={RESEND_ORDER_INVOICE} onError={this.onError}>
        {(update, { loading, error }) => (
          <SendToGpBtn order={order} update={update} btnText={btnText} />
        )}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

ResendOrderInvoice.propTypes = {
  order: OrderObject.isRequired,
  btnText: PropTypes.string.isRequired
};

export default ResendOrderInvoice;
